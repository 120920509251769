import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import { useParams } from "react-router-dom";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import Modal from "../../atoms/modal";
import HostToCoordinator from "../../../resources/images/host-to-coordinator.png";

import NewCoordinator from "../../../resources/images/new-coordinator.png";
import Candidate from "../../../resources/images/candidate.png";
import DirectoAvatar from "../../../resources/images/director-avatar.png";
import PauseIcon from "../../../resources/images/pause.png";
import SaludoCaptain from "../../../resources/images/Saludo.png";
import Megaphone from "../../../resources/images/megaphone.png";
import WarningIcon from "../../../resources/images/warning.png";
import GoodHand from "../../../resources/images/good-hand-coordinator.png";
import GoodHandCaptain from "../../../resources/images/good-hand-captain.png";
import GoodHandDirector from "../../../resources/images/good-hand-director.png";
import ClapHand from "../../../resources/images/clap-hand-coordinator.png";
import ClapHandCaptain from "../../../resources/images/clap-captain.png";
import ClapHandDirector from "../../../resources/images/clap-hand-director.png";
import ActividadSeleccion from "../../../resources/images/ActividadSeleccion.png";
import Book from "../../../resources/images/book.png";
import TableNewDirectorAngry from "../../../resources/images/table_new_director_angry.png";
import ProfileTwobo from "../../../resources/images/profile-twobo.png";
import NewCaptain from "../../../resources/images/director-avatar.png";
import Mic from "../../../resources/images/Mic.png";
import CalendarCheckHost from "../../../resources/images/calendar-check-host.png";
import ScreenTools from "../../../resources/images/screen-tools.png";
import MoneyHand from "../../../resources/images/money-hand.png";
import ArrowSmall from "../../../resources/images/Arrow-small.png";
import NewHost from "../../../resources/images/new-host.png";
import NewDirector from "../../../resources/images/director-avatar.png";
import TableNewDirector from "../../../resources/images/table_new_director.png";
import ConfirmHostGif from "../../../resources/images/confirm-host-gif.png";
import Vector from "../../../resources/images/Vector.png";
import Group951 from "../../../resources/images/Group951.png";
import Group269 from "../../../resources/images/Group269.png";

import { ReactComponent as TimeManagementMedal } from "../../../resources/images/time-management-medal.svg";
import Grupo547 from "../../../resources/images/Group547.png";
import { ReactComponent as RecognitionMedal } from "../../../resources/images/recognition-medal.svg";
import { ReactComponent as RecognitionMedalJustTime } from "../../../resources/images/just-in-time-coordinator.svg";
import JustInTime from "../../../resources/images/just-in-time-coordinator.svg";
import DirectorEpaDayTime from "../../../resources/images/DirectorEpaDayTime.png";
import DriverTimeDirector from "../../../resources/images/DriverTimeDirector.png";
import RecognitionMedalNew from "../../../resources/images/recognition-medal.svg";

import HostTimeSmall from "../../../resources/images/HostTimeSmall.png";
import CoordinatorTime from "../../../resources/images/CoordinatorTime.png";
import DirectorMediun from "../../../resources/images/DirectorMediun.png";
import {
  getCandidates,
  getCaptainAndHostWithAspirants,
  getCaptainAndHostWithAspirantsMap,
  getGeneralUserTable,
  getListOfHostWithTheirSelectApplicants,
  postAwardindMedalsToTheRooms,
} from "../../../middleware/services/epaCoordinatorServices";
import { API, ROLES } from "../../../middleware/utils/constants";
import MedalSwitch from "../../molecules/medal-switch";
import {
  assignMedal,
  getHostWinners,
  pushEvent,
  pushMedalEvent,
  saveResponseChallengeEpaRequirements,
} from "../../../middleware/services/common";
import Checkbox from "../../atoms/checkbox";
import { QueriesListNewRoles } from "../../../middleware/services/useQueryListNewRolesServices";
import { QueriesUsersBelowBydId, useQuerieListOfHostWithTheirSelectApplicantsBydId } from "../../../middleware/services/useQueryUsersBelowCoordinatot";
import axios from "axios";
import "./index.scss";
import { QueriesEpaDateCoordinatingSchedulesById } from "../../../middleware/services/useQueryEpaDateCoordinatingSchedules";
import { Toggle } from "../../atoms/switch";
import Card from "../../atoms/card";
import MenuAspiranteInfo from "../../pages/epa/coordinador/coodinadorSalaA/MenuAspitanteInfo";
import Select from "../../atoms/select";
import CaptainAndHostWithAspirants from "../../molecules/captain-and-host-with-aspirants";
import RequirementsConfirmationActivity from "../../molecules/requirements-confirmation-activity";
import ConfidentialityEpa from "../../molecules/confidentiality-epa";
import CoordinadorByAnfitrionByAspirantesList from "../../molecules/coordinador-by-anfitrion-by-aspirantes-list";
import ActivitySelectedCoordinator from "../../molecules/activity-selected-coordinator";
import NowItIsyourTurnToSelect from "../../molecules/now-it-is-your-turn-to-select";
import ActivitySelectedTwo from "../../molecules/activity-selected-two";
import ActivitySeletedModal from "../../molecules/activity-seleted-modal";
import DirectorCaptainAndHostWithAspirants from "../../molecules/director-captain-and-host-with-aspirants copy";
import TimeManagementHostWinners from "../../molecules/time-management-host-winners";

const circle = {
  host: "⚪",
  "new host": "⚪",
  coordinator: "🔴",
  "new coordinator": "🔴",
  director: "🟡",
  captain: "🔵",
};

const DirectorEpaDay = ({ finishChallenge, step, setStep, ...props }) => {
  const { challengeName, idChallenge } = useParams();
  const [medals, setMedals] = useState([]);

  const { user, eventEpa, setEventEpa, changeRoleContext } =
    useContext(UserContext);
  const [captainAndHosts, setCaptainAndHosts] = useState([]);
  const [applicantsAndHosts, setApplicantsAndHosts] = useState([]);
  const { data: EpaDateCoordinatingS } =
    QueriesEpaDateCoordinatingSchedulesById(user._id);

  const { data } = QueriesListNewRoles(user?._id);
  const [users, setUsers] = useState([]);

  const [foundationsData, setFoundationsData] = useState([]);

  const { data: UsersBelow } = QueriesUsersBelowBydId(user?._id);
  const [hold, setHold] = useState(false);
  const [dedicate, setDedicate] = useState(false);
  const [participate, setParticipate] = useState(false);
  const [potentiate, setPotentiate] = useState(false);
  const [confidentiality, setConfidentiality] = useState(false);
  const [numPager, setNumPager] = useState(0);
  const [idCoordinatorDirector, setIdCoordinatorWithDirector] = useState("");
  useEffect(() => {
    if (UsersBelow) {
      setUsers(UsersBelow);
      const fetchData = async () => {
        try {
          const foundationPromises = UsersBelow.map(async (user) => {
            const response = await axios.get(
              `${API.FOUNDATIONS_SELECTED_BY_NEW_CANDIDATES.url}/${user._id}?action=foundations-selected-by-new-candidates`
            );

            return {
              userId: user._id,
              name: user.name,
              foundations: response.data.data, // Asumimos que los datos de fundaciones están aquí
            };
          });

          const allFoundationsData = await Promise.all(foundationPromises);
          setFoundationsData(allFoundationsData);
        } catch (error) {
          console.error("Error fetching foundation data:", error);
        }
      };

      fetchData();
    }
    if (EpaDateCoordinatingS != undefined) {
      EpaDateCoordinatingS.forEach((element, index) => {
        if (index == 1) {
          setIdCoordinatorWithDirector(element.idCoordinator);
        
        }
    
      });
    }
  }, [UsersBelow, EpaDateCoordinatingS]);
  const [inputDate, setInputDate] = useState({
    date: "",
    hour: "",
    status: true,
  });
  const [modal, setModal] = useState(false);
  const [nameCoordinator, setNameCoordinator] = useState("");
  const [idCoordinator, setIdCoordinator] = useState("");
  const handlerAccept = (row, id, name) => {
    setIdCoordinator(id);
    setNameCoordinator(name);
    setInputDate({
      date: row.date,
      hour: row.hour,
      status: true,
    });

    setModal(true);
  };
  const handlerEdit = (row) => {
    //setscheduleParam(row)
    setStep(step + 3);
  };
  const [value, setValue] = useState(false);
  const [countToggle, setCountToggle] = useState(0);
  const hadlerToggle = (id) => {
    console.log("id", id);
    // setSelectedToggle({ 'id': id, status: true, property: "" })
    setCountToggle(countToggle + 1);
    setValue(id);
  };

  const [handDirector, setHandDirector] = useState(false);

  const saveResponseChallengeA = async () => {
    const data = {
      maintainPerformance: hold,
      confidentialityAgreement: confidentiality,
      minutesByWhatsApp: dedicate,
      provisionTwoHours: participate,
      enhanceTheThreeHours: potentiate,
    };

    await saveResponseChallengeEpaRequirements(data, user?._id);
    setStep(step + 1);
  };

  useEffect(() => {
    fetchData();
  }, [eventEpa]);
  const fetchData = async () => {
    const list = await getCaptainAndHostWithAspirants(
      idCoordinatorDirector?.idCoordinator
    );

    setCaptainAndHosts(list);
    const listOfHost = await getListOfHostWithTheirSelectApplicants(user?._id);
    setApplicantsAndHosts(listOfHost);
  };
  const changeRole = (role) => {
    const data = {
      role: `new captain`,
    };

    axios
      .put(`${API.CHANGE_OF_ROLE.url}/${user?._id}`, data)
      .then((response) => {
        if (response.status === 200) {
          setStep(step + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [hostCandidates, setHostCandidates] = useState([]);
  useEffect(() => {
    fetchDataHostCandidate();
  }, [ eventEpa]);
  const fetchDataHostCandidate = async () => {
    const listCandiadates = await getCaptainAndHostWithAspirants(idCoordinatorDirector);


    setHostCandidates(listCandiadates);
    return;
  };

  return (
    <>
      {challengeName === "Desafío Sala E" && (
        <>
          <Step
            order={1}
            visibility={step === 1 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <img src={NewCaptain} alt="new-host" width={209} height={209} />
            <span className="title">
              <b>¡Director(a)!</b>
            </span>
            <Paragraph size="s" align="left" className="p-room-p-1">
              En el desafío de la Sala Épica tus funciones son:
            </Paragraph>
            <Paragraph size="s" align="left" className="p-room-p-1">
              1. Entregar las medallas de Manejo del Tiempo 🥇 a tu
              Coordinador(a) por sus Notas de voz en el grupo de WhatsApp.
            </Paragraph>
            <br />
            <Paragraph size="s" align="left" className="p-room-p-1">
              2. Compartir tu PDL. (Podrás ganar medalla de Manejo del Tiempo
              🥇)
            </Paragraph>
            <br />
            <Paragraph size="s" align="left" className="p-room-p-1">
              3. Completar las pausas del video de la Sala Épica máximo 30
              minutos después de la hora del inicio del EPA para ganar la
              medalla de Justo a Tiempo ⏱️ en equipo.
            </Paragraph>
            <br />
            <Button onClick={() => setStep(step + 1)}>Continuar</Button>
          </Step>
          <Step
            order={2}
            visibility={step === 2 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <br />
            <Paragraph size="s" align="center" className="color-paragraph">
              <b>Actividad PDL</b>
            </Paragraph>
            <Paragraph
              size="s"
              align="center"
              className="color-paragraph p-room-p-0"
            >
              ¡Director(a)! Te encuentras en el Desafío de la Sala Épica, aquí
              compartirás a tu equipo ACDC tu PDL (Propósito, Destino y Legado).
            </Paragraph>
            <div className="warning-card-room-e-1 ">
              <img
                src={WarningIcon}
                alt="warning"
                className="warning "
                width={98}
                height={93}
              />
            </div>
            <br />
            <div className="warning-card  warning-card-box-room-e-1 ">
              <Paragraph
                size="s"
                align="center"
                className="color-paragraph p-room-p-2"
              >
                Recuerda que para ganar la medalla, la Nota de voz debe ser de
                0:50 a 1:10.
              </Paragraph>
            </div>
            <Button onClick={() => setStep(step + 1)}>Continuar</Button>
          </Step>

          <Modal
            className="confirmation-modal step epa-day-modal"
            isOpen={step === 3}
          >
            <img src={PauseIcon} alt="pause-icon" width={106} height={106} />
            <span className="title">Director(a)!</span>
            <Paragraph align="center" size="s">
              Te encuentras en una pantalla de Pausa Global, ningún miembro del
              EPA podrá avanzar hasta que tú lo hagas.
              <br />
              <br />
              Podrás avanzar cuando todos hayan recibido o no, la medalla por la
              actividad.
            </Paragraph>

            <Button size="small" onClick={() => setStep(step + 1)}>
              ¡Vale!
            </Button>
          </Modal>
          <Step
            order={4}
            visibility={step === 4 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <br />
            <Paragraph size="s" align="center" className="color-paragraph">
              <b>Actividad PDL</b>
            </Paragraph>
            <Paragraph align="center" className="p-room-p-0" size="s">
              En esta actividad también deberás
              <br />
              entregar las medallas de Manejo del Tiempo 🥇 <br />a tu
              Coordinador(a).
            </Paragraph>
            <br />
            <div className="tutorial-time-management">
              <div className="tutorial-time-management__info director-info">
                <Paragraph size="s" align="center">
                  <br />
                  <img
                    src={DriverTimeDirector}
                    alt="direver time director"
                    className="director"
                    width="33.75"
                    height="29.9"
                  />
                </Paragraph>
                <Paragraph size="s" align="center">
                  Presiona SÍ, en el participante que haya ganado medalla de{" "}
                  <b>Manejo del Tiempo</b>
                  y No, en el caso contrario.
                  <br />
                  <br />
                </Paragraph>
              </div>
              <DirectorCaptainAndHostWithAspirants
                idCoordinator={idCoordinatorDirector}
                medals={medals}
                setMedals={setMedals}
                className="directo-switch"
                role=""
              />
            </div>

            <Button
              className="director"
              onClick={() => setStep(step + 1)}
              disabled={medals.length === 0}
            >
              Continuar
            </Button>
          </Step>

          <Modal
            className="host-alignment-day-container epa-confirmation-modal"
            isOpen={step === 5 || step === 9}
          >
            <Paragraph size="s" align="center">
              ¿Estás seguro(a) que deseas continuar?
            </Paragraph>
            <Paragraph size="s" align="center" className="p-room-p-1">
              Asegúrate de que todo el equipo se encuentra sincronizado para
              continuar con las actividades del EPA.
            </Paragraph>
            <div className="buttons">
              <Button
                size="small"
                onClick={() => {
                  setStep(step + 1);
                  setHandDirector(true);
                }}
              >
                Sí
              </Button>
              <Button
                size="small"
                onClick={() => {
                  setStep(step + 1);
                  setHandDirector(false);
                }}
              >
                No
              </Button>
            </div>
          </Modal>

          <Step
            handDirector={handDirector === true}
            order={6}
            visibility={step === 6 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <br />
            {handDirector === false ? (
              <>
                <Paragraph align="center" size="s" className="p-room-p-0">
                  ¡Ups! Lamentablemente, no cumpliste con el tiempo establecido.
                  <br />
                </Paragraph>
                <img
                  src={GoodHandDirector}
                  alt="pause-icon"
                  width={162}
                  height={162}
                  style={{ transform: "rotate(160deg)" }}
                />
                <Paragraph align="center" size="s">
                  En esta ocasión, no ganaste medalla de Manejo del Tiempo
                  ¡Podrás mejorar tu desempeño durante el EPA!
                  <br />
                </Paragraph>
                <br />
              </>
            ) : (
              <>
                <span className="title">¡Felicitaciones!</span>
                <Paragraph align="center" size="s" className="p-room-p-0">
                  Tu participación compartiendo tu PDL con el equipo fue
                  perfecta. <br />
                  <br />
                </Paragraph>
                <img
                  src={GoodHandDirector}
                  alt="pause-icon"
                  width={162}
                  height={162}
                />
                <Paragraph align="center" size="s">
                  ¡Has ganado una medalla de <b>Manejo del Tiempo!</b>
                  <br />
                </Paragraph>
                <br />
              </>
            )}

            <img
              src={DirectorMediun}
              alt="Director medium"
              className="director"
              width={89}
              height={79}
            />
            <Button onClick={() => setStep(step + 1)}>Ver video</Button>
            <Button
              style="ghost"
              size="small"
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Modal
            className="confirmation-modal step epa-day-modal"
            isOpen={step === 7}
          >
            <img src={PauseIcon} alt="pause-icon" width={106} height={106} />
            <span className="title">¡Director(a)!</span>
            <Paragraph align="center" size="s">
              Te encuentras en una pantalla de Pausa Global, ningún miembro del
              EPA podrá avanzar hasta que tú lo hagas.
              <br />
              <br />
              Procura avanzar cuando el equipo haya recibido la medalla en
              equipo por el(la) Coordinador(a).
            </Paragraph>

            <Button size="small" onClick={() => setStep(step + 1)}>
              ¡Vale!
            </Button>
          </Modal>
          <Step
            order={8}
            visibility={step === 8 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <br />

            <Paragraph align="center" size="s" className="p-room-p-0">
              <b>
                Visualiza si tu equipo ganó la medalla en <br />
                equipo de Justo a Tiempo de la Sala Épica
              </b>
              <br />
            </Paragraph>
            <br />
            <Paragraph className="p-room-p-0" size="s" align="canter">
              Para ganar esta medalla de equipo, tú como Director(a) debiste
              haber completado la pausa #4 de la Sala Épica máximo 30 minutos
              después de la hora de inicio del EPA.
            </Paragraph>
            <div className="tutorial-time-management">
              <div className="tutorial-time-management__info director-info">
                <Paragraph size="s" align="center">
                  <br />
                  <img
                    src={DriverTimeDirector}
                    alt="Driver time director"
                    className="director"
                    width="33.75"
                    height="29.9"
                  />
                </Paragraph>
                <Paragraph size="s" align="center">
                  En la siguiente tabla podrás ver las Salas en las que has
                  ganado Medallas en equipo de<b>Justo a Tiempo</b>
                  <br />
                  <br />
                </Paragraph>
              </div>
              <TimeManagementHostWinners
                idCoordinator={idCoordinatorDirector}
                className="directo-switch"
                role="coordinator"
              />
            </div>

            <Button onClick={() => setStep(step + 1)} className="director">
              Continuar
            </Button>
          </Step>
          <Modal
            className="confirmation-modal step epa-day-modal"
            handDirector={handDirector === true}
            isOpen={step === 10}
          >
            <span className="title">¡Felicitaciones equipo ACDC!</span>
            <Paragraph align="center" size="s">
              Lograron completar las actividades de la Sala Épica Justo a
              Tiempo.
              <br />
              <br />
            </Paragraph>
            <img
              src={DirectorEpaDayTime}
              alt="pause-icon"
              width={106}
              height={106}
            />
            <Paragraph align="center" size="s">
              ¡Han ganado una medalla en equipo de <b>Justo a Tiempo!</b>
              <br />
              <br />
            </Paragraph>

            <Button size="small" onClick={() => setStep(step + 1)}>
              ¡Vale!
            </Button>
          </Modal>
          <Modal
            className="confirmation-modal step epa-day-modal"
            handDirector={handDirector === false}
            isOpen={step === 10}
          >
            <Paragraph align="center" size="s">
              ¡Ups! Lamentablemente, no cumplieron con el tiempo establecido.
              <br />
              <br />
            </Paragraph>

            <Paragraph align="center" size="s">
              En esta ocasión, no ganaron medalla en equipo de Justo a Tiempo
              ¡Podrán mejorar su desempeño durante el EPA!
              <br />
              <br />
            </Paragraph>

            <Button size="small" onClick={() => setStep(step + 1)}>
              ¡Vale!
            </Button>
          </Modal>

          <Step
            order={11}
            visibility={step === 11 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <br />
            <div>
              <img src={TableNewDirectorAngry} />
              <Paragraph align="left" className="text-hungry " size="s">
                “Necesitamos líderes con un idealismo inspirador, líderes que
                cuenten con grandes visiones, que sueñen en grande y que luchen
                por esos sueños para que se vuelvan realidad, personas que
                logren encender a los demás con la chispa de sus propias almas
                apasionadas”
                <br />
                <br />
                <b>-Theodore Roosevelt.</b>
              </Paragraph>
            </div>
            <div className="phrase-container">
              <img src={Book} alt="Book" className="book" />
              <Paragraph size="s" align="left" className="gift-text-phrase ">
                Fragmento tomado del Libro
                <br />
                <b>Lanzando una Revolución sobre el Liderazgo.</b>
              </Paragraph>
            </div>
            <Button onClick={() => setStep(step + 1)}>Continuar</Button>
          </Step>
          <Step
            order={12}
            visibility={step === 12 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <br />
            <Paragraph size="s" align="center">
              <b>¡Genial!</b>
            </Paragraph>
            <Paragraph size="s" align="center">
              Has completado el Desafío de la<b> Sala Épica</b>
            </Paragraph>
            <img
              src={ClapHandDirector}
              alt="Clap Hand"
              className="good-hands"
            />
            <Paragraph size="s" align="center" className="card-host-captain">
              Ya puedes iniciar el desafío <br />
              de la
              <b>Sala Plataforma.</b>
              <br />
              <br />
              <b> 👋🏻 Ya nos vemos.</b>
            </Paragraph>

            <Button onClick={() => finishChallenge(null)}>Finalizar</Button>
          </Step>
        </>
      )}

      {/*new capitan humiildad*/}
      {challengeName === "Desafío Sala P" && (
        <>
          <Step
            order={1}
            visibility={step === 1 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <img src={NewCaptain} alt="new-host" width={209} height={209} />
            <Paragraph size="s" align="left" className="p-room-p-0">
              ¡Director(a) eres quien dirigirá las actividades del EPA! En el
              desafío de la Sala Plataforma tus funciones son:
              <br />
              <br />
            </Paragraph>
            <Paragraph size="s" align="left" className="p-room-p-0">
              1. Entregar la medalla de Manejo del Tiempo 🥇 a tu Coordinador(a)
              por compartir su experiencia en su rol a través de Notas de voz en
              el grupo de WhatsApp.
              <br />
              <br />
            </Paragraph>

            <Paragraph size="s" align="left" className="p-room-p-0">
              2. Completar las pausas del video de la Sala Plataforma máximo 30
              minutos después de la hora del inicio del EPA para ganar la
              medalla de Justo a Tiempo ⏱️ en equipo.
              <br />
              <br />
            </Paragraph>
            <Button onClick={() => setStep(step + 1)}>Continuar</Button>
          </Step>

          <Step
            order={2}
            visibility={step === 2 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <br />
            <Paragraph size="s" align="center" className="color-paragraph">
              <b>Actividad compartir Juego de Pulgares</b>
            </Paragraph>
            <br />
            <Paragraph align="center" className="p-room-p-0" size="s">
              Los Aspirantes recibirán automáticamente su medalla al hacer la
              puntuación
            </Paragraph>
            <br />
            <div className="tutorial-time-management">
              <div className="tutorial-time-management__info director-info">
                <Paragraph size="s" align="center">
                  <br />
                  <img
                    src={RecognitionMedalNew}
                    alt=""
                    className="director"
                    width="33.75"
                    height="29.9"
                  />
                </Paragraph>
                <Paragraph size="s" align="center">
                  En esta tabla puedes visualizar los miembros del equipo que
                  ganaron o no, su medalla de Reconocimiento
                  <br />
                  <br />
                </Paragraph>
              </div>

              <DirectorCaptainAndHostWithAspirants
                idCoordinator={idCoordinatorDirector}
                medals={medals}
                setMedals={setMedals}
                className="directo-switch"
                role="candidate"
              />
            </div>

            <Button className="director" onClick={() => setStep(step + 1)}>
              Continuar
            </Button>
          </Step>

          <Step
            order={3}
            visibility={step === 3 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <img src={NewCaptain} alt="new-host" width={222} height={230} />
            <Paragraph size="s" align="center" className="p-room-p-0">
              Director espera a que el resto del equipo lleguen a la pausa #8
              para poder continuar con la actividad de los Anfitriones
              compartiendo su experiencia.
              <br />
              <br />
            </Paragraph>
            <img src={Mic} alt="new-host" width={112} height={102} />
            <Paragraph size="s" align="left" className="p-room-p-0">
              Sólo puedes dar click en continuar una vez el equipo este
              sincronizado en la pausa #8. <br />
              <br />
            </Paragraph>

            <Button onClick={() => setStep(step + 1)}>Continuar</Button>
          </Step>

          <Step
            order={4}
            visibility={step === 4 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <br />

            <Paragraph align="center" size="s" className="p-room-p-0">
              <b>Actividad compartir experiencia del rol</b>
              <br />
            </Paragraph>
            <br />
            <Paragraph className="p-room-p-0" size="s" align="canter">
              Los dos Anfitriones van a compartir cómo fue su experiencia
              cumpliendo las funciones de su rol.
              <br />
              <br />
            </Paragraph>
            <Paragraph className="p-room-p-2" size="s" align="canter">
              Revisa que el Coordinador entregue las medallas de Manejo del
              Tiempo 🥇 a los Anfitriones
              <br />
              <br />
            </Paragraph>
            <div className="tutorial-time-management">
              <div className="tutorial-time-management__info director-info">
                <Paragraph size="s" align="center">
                  <br />
                  <img
                    src={HostTimeSmall}
                    alt=""
                    className="director"
                    width="33.75"
                    height="29.9"
                  />
                </Paragraph>
                <Paragraph size="s" align="center">
                  En esta tabla puedes visualizar los miembros del equipo que
                  ganaron o no, su medalla de
                  <b> Manejo del Tiempo</b>
                  <br />
                  <br />
                </Paragraph>
              </div>
              <DirectorCaptainAndHostWithAspirants
                idCoordinator={idCoordinatorDirector}
                medals={medals}
                setMedals={setMedals}
                className="directo-switch"
                role="host"
              />
            </div>

            <Button onClick={() => setStep(step + 1)} className="director">
              Continuar
            </Button>
          </Step>

          <Step
            order={5}
            visibility={step === 5 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <br />

            <Paragraph align="center" size="s" className="p-room-p-0">
              <b>Actividad compartir experiencia del rol</b>
              <br />
            </Paragraph>
            <br />
            <Paragraph className="p-room-p-0" size="s" align="canter">
              ¡Director(a)! Te encuentras en el Desafío de la Sala Plataforma,
              tu Coordinador(a) va a compartir cómo fue su experiencia
              cumpliendo las funciones de su rol.
              <br />
              <br />
            </Paragraph>
            <Paragraph className="p-room-p-2" size="s" align="canter">
              Entregarás la medalla de Manejo del Tiempo 🥇 , si su Nota de voz
              está de 1:00 a 2:00.
              <br />
              <br />
            </Paragraph>
            <div className="tutorial-time-management">
              <div className="tutorial-time-management__info director-info">
                <Paragraph size="s" align="center">
                  <br />
                  <img
                    src={CoordinatorTime}
                    alt=""
                    className="director"
                    width="33.75"
                    height="29.9"
                  />
                </Paragraph>
                <Paragraph size="s" align="center">
                  Presiona SÍ, en el participante que haya ganado medalla de{" "}
                  <b>Manejo del Tiempo </b>
                  y No, en el caso contrario
                  <br />
                  <br />
                </Paragraph>
              </div>
              <DirectorCaptainAndHostWithAspirants
                idCoordinator={idCoordinatorDirector}
                medals={medals}
                setMedals={setMedals}
                className="directo-switch"
                role="coordinator"
              />
            </div>
            <Button onClick={() => setStep(step + 1)} className="director">
              Continuar
            </Button>
          </Step>
          <Step
            order={6}
            visibility={step === 6 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <br />
            <Paragraph align="center" size="s" className="p-room-p-0">
              <b>
                Visualiza si tu equipo ganó la medalla en <br />
                equipo de Justo a Tiempo de la Sala Épica
              </b>
              <br />
            </Paragraph>
            <br />
            <Paragraph className="p-room-p-0" size="s" align="canter">
              Para ganar esta medalla de equipo, debiste haber completado la
              actividad de la pausa #9 de la Sala Plataforma máximo 1 hora y 10
              minutos después del inicio del EPA.
            </Paragraph>
            <div className="tutorial-time-management">
              <div className="tutorial-time-management__info director-info">
                <Paragraph size="s" align="center">
                  <br />
                  <img
                    src={DriverTimeDirector}
                    alt=""
                    className="director"
                    width="33.75"
                    height="29.9"
                  />
                </Paragraph>
                <Paragraph size="s" align="center">
                  En la siguiente tabla podrás ver las Salas en las que has
                  ganado Medallas en equipo de<b>Justo a Tiempo</b>
                  <br />
                  <br />
                </Paragraph>
              </div>
              <TimeManagementHostWinners
                idCoordinator={idCoordinatorDirector}
                className="directo-switch"
                role="coordinator"
              />
            </div>
            <Button onClick={() => setStep(step + 1)} className="director">
              Continuar
            </Button>
          </Step>
          <Step
            order={7}
            visibility={step === 7 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <br />
            <Paragraph size="s" align="center">
              <b>¡Genial!</b>
            </Paragraph>
            <Paragraph size="s" align="center">
              Has completado el Desafío de la <b>Sala Plataforma</b>
            </Paragraph>
            <img
              src={ClapHandDirector}
              alt="Clap Hand"
              className="good-hands"
            />
            <Paragraph size="s" align="center" className="card-host-captain">
              Ya puedes iniciar el desafío <br />
              de la
              <b>Sala Plataforma.</b>
              <br />
              <br />
              <b> 👋🏻 Ya nos vemos.</b>
            </Paragraph>
            <Button onClick={() => finishChallenge(null)}>Finalizar</Button>
          </Step>
        </>
      )}

      {challengeName === "Desafío Sala A" && (
        <>
          <Step
            order={1}
            visibility={step === 1 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <span className="title">
              <b>Actividad confirmación de requisitos</b>
            </span>
            <br />
            <Card type="secondary card-actity">
              <Paragraph size="s" align="center" className="p-room-p-1">
                Coordinador(a), los Aspirantes de tus Anfitriones en el PREPA
                confirmaron los primeros 4 requisitos.
                <br />
                <br />
                A continuación te los recordamos:
                <br />
                <br />
              </Paragraph>
            </Card>
            <RequirementsConfirmationActivity
              className=""
              hold={hold}
              setHold={setHold}
              dedicate={dedicate}
              setDedicate={setDedicate}
              participate={participate}
              setParticipate={setParticipate}
              potentiate={potentiate}
              setPotentiate={setPotentiate}
            />

            <br />
            <Button
              onClick={() => setStep(step + 1)}
              disabled={!hold || !dedicate || !participate || !potentiate}
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={2}
            visibility={step === 2 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <br />
            <Paragraph size="s" align="center" className="custom-border-color ">
              <div className="border-room-a-1">
                Sin embargo, para seguir avanzando necesitas confirmar el{" "}
                <b>Quinto requisito</b> que es esencial para ser parte de
                nuestra <br /> comunidad épica:
              </div>
            </Paragraph>
            <br />
            <ConfidentialityEpa
              confidentiality={confidentiality}
              setConfidentiality={setConfidentiality}
            />
            <br />
            <img src={ActividadSeleccion} alt="List" width={280} />
            <br />
            <br />
            <Button
              onClick={saveResponseChallengeA}
              disabled={!confidentiality}
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={3}
            visibility={step === 3 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <CoordinadorByAnfitrionByAspirantesList
              idCoordinator={idCoordinatorDirector}
            />
            <br />
            <Button className="btn-gift-2" onClick={() => setStep(step + 1)}>
              Continuar
            </Button>
          </Step>
          <Step
            order={4}
            visibility={step === 4 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <ActivitySelectedCoordinator
              title="Actividad Selección #1"
              descriptionOne="     Director(a), estas son las respuestas de tu Coordinador a la siguiente pregunta:"
              descriptionTwo="Si la decisión de quiénes son tus dos dependiera solo de ti, ¿a quiénes seleccionarías?"
              className="custom-border-color-red"
              icon="🔴"
              hostCandidates={hostCandidates}
            />
            <br />
            <br />
            <Button
              className="btn-gift-2"
              onClick={() => setStep(step + 1)}
              disabled={value}
            >
              Continuar
            </Button>
          </Step>

          <Step
            order={5}
            visibility={step === 5 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <span className="title">
              <b>¡Ahora es tu turno de seleccionar!</b>
            </span>
            <Paragraph size="s" align="center" className="p-room-p-1">
              Director(a), si la decisión de quienes son los dos Aspirantes
              seleccionados de cada Anfitrión de tu Coordinador, dependiera solo
              de ti,
              <br /> ¿a quiénes seleccionarías?
            </Paragraph>
            <NowItIsyourTurnToSelect
              setStep={setStep}
              step={step}
              applicantsAndHosts={applicantsAndHosts}
              className="custom-border-color-red"
              icon="🔴"
              idCoordinator={idCoordinatorDirector}
            />
            <br />
            <br />
          </Step>
          <Step
            order={6}
            visibility={step === 6 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <span className="title">
              <b>Actividad Selección #2</b>
            </span>
            <br />
            <Paragraph size="s" align="center" className="p-room-p-1">
              Director(a), estas son las respuestas de los Anfitriones a la
              siguiente pregunta:
              <br />
              <br />
            </Paragraph>
            <Paragraph size="s" align="center" className="p-room-p-1">
              De los PDLs de tus Aspirantes, ¿cuál está más conectado con tu
              PDL?
            </Paragraph>
            <ActivitySelectedTwo
              setNumPager={setNumPager}
              setStep={setStep}
              step={step}
              idCoordinator={idCoordinatorDirector}
            />
          </Step>
          <Step
            order={7}
            visibility={step === 7 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <span className="title">
              <b>Actividad Selección #2</b>
            </span>
            <br />
            <Paragraph size="s" align="center" className="p-room-p-1">
              Director(a), estas son las respuestas de los Anfitriones a la
              siguiente pregunta:
              <br />
              <br />
            </Paragraph>
            <Paragraph size="s" align="center" className="p-room-p-1">
              De los PDLs de tus Aspirantes, ¿cuál está más conectado con tu
              PDL?
            </Paragraph>
            <ActivitySeletedModal
              numPager={numPager}
              setNumPager={setNumPager}
              setStep={setStep}
              step={step}
              idCoordinator={idCoordinatorDirector}
            />
          </Step>
          <Step
            order={8}
            visibility={step === 8 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <span className="title">
              <b>
                Visualiza si tu equipo ganó la medalla en
                <br /> equipo de Justo a Tiempo
              </b>
            </span>
            <br />
            <br />
            <Paragraph size="s" align="center" className="p-room-p-1">
              Para ganar esta medalla de equipo, los dos Anfitriones deben
              completar el entrenamiento máximo 1 hora con 45 minutos después de
              la hora de inicio del EPA.
            </Paragraph>
            <br />
            <div className="tutorial-time-management">
              <div className="tutorial-time-management__info director-info">
                <Paragraph size="s" align="center">
                  <br />
                  <img
                    src={DirectorEpaDayTime}
                    alt=""
                    className="director"
                    width="33.75"
                    height="29.9"
                  />
                </Paragraph>
                <Paragraph size="s" align="center">
                  En la siguiente tabla podrás ver las Salas en las que has
                  ganado Medallas en equipo de <b>Justo a Tiempo</b>
                  <br />
                  <br />
                </Paragraph>
              </div>
            </div>
            <TimeManagementHostWinners
              idCoordinator={idCoordinatorDirector}
              className="directo-switch"
              role="coordinator"
            />

            <Button onClick={() => setStep(step + 1)} className="director">
              Continuar
            </Button>
          </Step>
          <Modal
            className="confirmation-modal step epa-day-modal"
            isOpen={step === 9}
          >
            <span className="title">¡Felicitaciones equipo ACDC!</span>
            <Paragraph align="center" size="s">
              Lograron completar las actividades de la Sala Épica Justo a
              Tiempo.
              <br />
              <br />
            </Paragraph>
            <img
              src={DirectorEpaDayTime}
              alt="pause-icon"
              width={106}
              height={106}
            />
            <Paragraph align="center" size="s">
              ¡Han ganado una medalla en equipo de <b>Justo a Tiempo!</b>
              <br />
              <br />
            </Paragraph>

            <Button size="small" onClick={() => setStep(step + 1)}>
              ¡Vale!
            </Button>
          </Modal>
          <Modal
            className="confirmation-modal step epa-day-modal"
            isOpen={step === 10}
          >
            <Paragraph align="center" size="s">
              ¡Equipo ACDC!
              <br />
              <br />
            </Paragraph>

            <Paragraph align="center" size="s">
              ¡Ups! Lamentablemente, no cumplieron con el tiempo establecido.
              <br />
              En esta ocasión, no ganaron medalla en equipo de Justo a Tiempo
              ¡Podrán mejorar su desempeño durante el EPA!
              <br />
              <br />
            </Paragraph>
            <Button size="small" onClick={() => setStep(step + 1)}>
              ¡Vale!
            </Button>
          </Modal>
          <Step
            order={11}
            visibility={step === 11 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <br />
            <Paragraph size="s" align="center">
              <b>¡Genial!</b>
            </Paragraph>
            <Paragraph size="s" align="center">
              Has completado el Desafío de la <b>Sala de Admisiones.</b>
            </Paragraph>
            <img
              src={ClapHandDirector}
              alt="Clap Hand"
              className="good-hands"
            />
            <Paragraph size="s" align="center" className="card-host-captain">
              Ahora que ya terminaste los 3 Desafíos de las Salas del EPA y
              conoces quienes <br />
              cumplen los requisitos para quedar seleccionados
              <br />
              <br />
              Ya puedes pasar al Cambio de Rol donde se definirá quienes quedan
              seleccionados y quienes quedan en Lista de Espera .
              <br />
              <b>👋🏻 Ya nos vemos.</b>
            </Paragraph>

            <Button onClick={() => finishChallenge(null)}>Finalizar</Button>
          </Step>
        </>
      )}
      {challengeName === "Cambio de Rol" && (
        <>
          <Step
            order={1}
            visibility={step === 1 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <br />

            <Paragraph size="s" align="center" className="card-host-captain">
              Actividad Cambio de Rol
              <br />
            </Paragraph>
            <div class="img-change-directo-to-new-captain">
              <img
                src={Group951}
                alt="directoToChangeNewCaptain"
                className="base-image"
              />
              <img src={Group269} alt="NewCaptain" className="overlay-image" />
            </div>
            <br />
            <Paragraph size="s" align="center">
              Presiona el botón “Convertirme en Nuevo(a) Capitán(a)” si lo ves
              habilitado, de lo contrario espera las indicaciones.
            </Paragraph>
            {/*!changeRoleContext?.status*/}
            <Button
              onClick={() => {
                changeRole();
              }}
              disabled={false}
            >
              Convertirme en Nuevo(a) Capitán(a)
            </Button>
          </Step>
          <Step
            order={2}
            visibility={step === 2 ? "visible" : "hidden"}
            className="epa-day-container"
          >
            <Paragraph size="s" align="center">
              ¡Felicitaciones!
            </Paragraph>

            <Paragraph size="s" align="center">
              Ahora eres el(la) Nuevo(a) Capitán(a). A partir de este momento,
              no tendrás que asistir a más EPAs, solo tendrás que estar atento
              de tu celular para ser TWOBOT.
            </Paragraph>
            <img src={Group269} alt="New Captain" width={194} height={194} />

            <Paragraph size="s" align="center">
              Ahora que eres el Nuevo(a) Capitán(a), continúa con el Final del
              EPA en tu nuevo rol.
            </Paragraph>
            <Button onClick={() => {}}>Ver video</Button>
            <Button
              id="finish"
              onClick={() => setStep(step + 1)}
              style="ghost"
              size="small"
            >
              Continuar
            </Button>
            {/* <Button id="finish" onClick={() => finishChallenge(null)} style="ghost" size="small">Continuar</Button> */}
          </Step>
          <Modal
            className="confirmation-modal step epa-day-modal"
            isOpen={step === 3}
          >
            <img
              src={SaludoCaptain}
              alt="pause-icon"
              width={106}
              height={106}
            />
            <span className="title">
              ¡Bienvenido(a) a la Plataforma
              <br /> ACDC!
            </span>
            <Paragraph align="center" size="s">
              💪🏼 Tu rol: Nuevo(a) Capitán(a)
              <br />
              📶 Nivel de Influencia 5: Formación de <br />
              líderes que forman líderes. <br />
              <br />
            </Paragraph>

            <Button size="small" onClick={() => setStep(step + 1)}>
              ¡Vale!
            </Button>
          </Modal>
          <Modal
            className="confirmation-modal step epa-day-modal"
            isOpen={step === 4}
          >
            <img src={Megaphone} alt="pause-icon" width={106} height={106} />
            <span className="title">😉 Recordatorio 👇🏼</span>
            <Paragraph align="center" size="s">
              No hay respuestas correctas o incorrectas.
              <br />
              <br />
              TWOBOT 🤖 y el equipo ACDC estarán contigo hasta el final.
              <br />
            </Paragraph>

            <Button
              size="small"
              id="finish"
              onClick={() => finishChallenge(null)}
            >
              ¡Vale!
            </Button>
            {/* <Button id="finish" onClick={() => finishChallenge(null)} style="ghost" size="small">Continuar</Button> */}
          </Modal>
        </>
      )}
    </>
  );
};

export default DirectorEpaDay;
