import { useNavigate, useParams } from "react-router-dom";
import Step from "../../molecules/step";
import WarningIcon from "../../../resources/images/warning.png";
import ClapHandCoordinator from "../../../resources/images/clap-hand-coordinator.png";
import Card from "../../atoms/card";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import { useContext, useEffect, useState } from "react";

import { API } from "../../../middleware/utils/constants";
import axios from "axios";
import { UserContext } from "../../../middleware/providers/user-context";
import { ReadingSection } from "../../molecules/reading-section";
import { Quote } from "../../molecules/quote";
import Megaphone from "../../../resources/images/megaphone.png";
import CoordinatorCheckList from "../../../resources/images/coordinator-check-list.png";
import Checkbox from "../../atoms/checkbox";
import { GenerationTable } from "../../molecules/generation-table";
import Modal from "../../atoms/modal";
import { CardCopyKey } from "../../molecules/card-copy-key";
import KeyHandCoordinator from "../../../resources/images/coordinator-h2-key.png";
import GoodHandCoordinator from "../../../resources/images/good-hand-coordinator.png";
import CheckList from "../../../resources/images/check-icon.png";
import Warning from "../../../resources/images/warning.png";
const CoordinatorSecondDay = ({
  finishChallenge,
  step,
  setStep,
  challenge,
}) => {
  const { challengeName, idChallenge } = useParams();
  const navigate = useNavigate();

  const { user } = useContext(UserContext);
  const [selectedZero, setSelectedZero] = useState(null);
  const [selectedOne, setSelectedOne] = useState(null);
  const [selectedTwo, setSelectedTwo] = useState(null);
  const [selectedThree, setSelectedThree] = useState(null);
  const [selectedFort, setSelectedFort] = useState(null);

  const saveResponse = () => {
    const data = {
      questions: dataQuestionOptions,
      action: "SAVE_SITUATIONS_TO_CHANGE",
    };

    axios
      .post(
        `${API.SAVE_SITUATIONS_TO_CHANGE.url}/${user?._id}/${idChallenge}/`,
        data
      )
      .then((response) => {
        if (response.status === 201) {
          finishChallenge(null);
          navigate("/missions");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCandidatAplicatProgressPoint(user?._id, "day two", 1);
  }, []);
  const [dataQuestionOptions, setDataQuestionOptions] = useState([]);

  const getCandidatAplicatProgressPoint = async (idUser, day, opt) => {
    const params = day ? { day } : undefined;
    try {
      const response = await axios.get(
        `${API.PROGRESS_POINT.url}/${idUser}/?action=PROGRESS_POINT`,
        {
          params,
        }
      );

      if (response.status === 200) {
        setDataQuestionOptions(response.data.data[1].questions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [isActiveBtn,setIsActiveBtn]=useState(false)
  const [modalIndexZero, setModalIndexZero] = useState(0);
  const [modalIndexOne, setModalIndexOne] = useState(0);
  const [modalIndexTwo, setModalIndexTwo] = useState(0);
  const [modalIndexThree, setModalIndexThree] = useState(0);
  const [modalIndexFour, setModalIndexFour] = useState(0);
const handlerModalZero=(index) => {
  setModalIndexZero(index)
}
const handlerModalOne=(index) => {
  setModalIndexOne(index)
}
const handlerModalTwo=(index) => {
  setModalIndexTwo(index)
}


  return (
    <>
      {challengeName === "Desafío A" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <div className="day-rules-info">
              <span className="greet">¡Hey!</span>
              <img src={WarningIcon} alt="warning-icon" />
              <Card className="card-warning" type="secondary">
                <Paragraph
                  className="card-warning__text"
                  align="center"
                  size="sx"
                >
                  <b>
                    Este desafío se realiza por fuera de la Plataforma ACDC.
                  </b>
                  <br />
                  <br />
                  Cuando tu Director(a) reciba y confirme <br />
                  que le enviaste la nota de voz <br />
                  correspondiente al <b>Desafío A</b> podrás <br /> continuar
                  con tus desafíos diarios.
                </Paragraph>
              </Card>
              <Button onClick={() => finishChallenge(step + 1)}>¡Vale!</Button>
            </div>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph size="s" align="center">
              <b>¡Genial!</b>
            </Paragraph>
            <Paragraph size="s" align="center">
              Has completado el <b>Desafío A.</b>
            </Paragraph>
            <img
              src={ClapHandCoordinator}
              alt="clap-hand-coordinator'"
              className="good-hand"
            />
            <Paragraph
              withBackground={true}
              size="s"
              align="center"
              type="secondary"
              style={{
                marginTop: "40px",
                padding: "35px 27px 21px",
                maxWidth: "289px",
              }}
            >
              Ya puedes continuar con el <b>Desafío B</b> del <br />{" "}
              <b>Día 2.</b>
              <br />
              <br />
              👋🏻 Ya nos vemos.
            </Paragraph>
            <Button onClick={() => navigate("/missions")}>¡Vale!</Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío B" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <ReadingSection>
              <Paragraph size="s" align="center">
                <b>Humildad</b>
              </Paragraph>
              <Paragraph
                size="sx"
                align="left"
                style={{
                  maxWidth: "316px",
                  width: "314px",
                }}
              >
                “Lo opuesto de arrogancia es la <br />
                humildad ¿Con quién debe ser humilde el <br />
                líder? La respuesta: con cualquiera que <br />
                tenga algo que enseñarle.” <br /> “Un líder debe estar
                sinceramente
                <br />
                interesado en aprender de una manera <br />
                regular. El desinterés o la apatía llevarán <br /> a un
                conocimiento viejo y una pobre
                <br /> habilidad para tomar decisiones.”
              </Paragraph>
            </ReadingSection>
            <Quote>
              <Paragraph size="s" align="left" style={{ margin: "0" }}>
                Tomado del libro:
              </Paragraph>
              <Paragraph
                size="s"
                align="left"
                style={{
                  margin: "0",
                  fontWeight: "700",
                }}
              >
                Lanzando una Revolución sobre el Liderazgo.
              </Paragraph>
            </Quote>
            <Button
              onClick={() => setStep(step + 1)}
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <img
              src={Megaphone}
              alt="clap-hand-coordinator"
              style={{
                marginTop: "54px",
              }}
            />

            <Paragraph
              align="center"
              size="s"
              style={{
                width: "377px",
                maxWidth: "377px",
              }}
            >
              Hoy has aprendido que el proveedor
              <br />
              seleccionado no solo permite llegar a{" "}
              <span style={{ color: "#1EFF00" }}>
                100 mil <br />
                dólares{" "}
              </span>
              al estar Asegurado Con Dos <br /> Conectados, sino que además
              podemos ganar bonos de liderazgo adicionales si tenemos más <br />
              de dos directos.
            </Paragraph>

            <Button
              onClick={() => setStep(step + 1)}
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
            >
              Continuar
            </Button>
          </Step>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph align="center" size="s">
              <b>Actividad Selecciona solo una respuesta</b>
            </Paragraph>
            <img
              src={CoordinatorCheckList}
              alt="coordinator-check-list"
              style={{
                marginTop: "10px",
              }}
            />
            <Paragraph
              align="center"
              size="s"
              style={{
                marginTop: "46px",
                width: "377px",
                maxWidth: "377px",
              }}
            >
              Ten en cuenta antes de responder, las <br /> respuestas están en
              el video que acabas de ver y <br /> lo que te respondió TWOBOT.
            </Paragraph>
            <Button
              onClick={() => setStep(step + 1)}
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
            >
              Continuar
            </Button>
          </Step>
          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "30px",
                maxWidth: "316px",
                width: "237px",
              }}
            >
              {dataQuestionOptions[0]?.question}
            </Paragraph>
            {dataQuestionOptions?.[0]?.options?.map((option, index) => (
              <Checkbox
                key={index}
                name="binary-bonus"
                label={option.option}
                checked={
                  (option.status = selectedZero === index ? true : false)
                }
                className=""
                style={{
                  width: "288px",
                  padding: "0px 0px 0px 35px",
                  marginTop: "49px",
                }}
                onChange={() => {
                  setSelectedZero(selectedZero === index ? null : index);
                  handlerModalZero(index+1);
               
                }}
              />
            ))}
            <Button
              onClick={() => {setStep(step + 1)
                setIsActiveBtn(false);
              }}
              disabled={isActiveBtn? false : true}
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
            >
              Aceptar
            </Button>
          </Step>
          <Modal
            isOpen={modalIndexZero ===3}
            className="confirmation-register-modal confirmation-captain-modal"
          >
            <div className="confirmation-register-container">
              <br />
              <img src={CheckList} alt="good hand" width="128" height="128"/>
              <Paragraph align="center" size="s">
                <b>¡Excelente!</b>
                <br />
                <br />
                ¡Has estado atento(a)!
                <br/>
                <br/>
                Efectivamente, con 1 persona a cada lado que compre un paquete ESP ya empiezas a recibir comisiones de Bono  Binario.
                <br />
                <br />
              </Paragraph>

              <Button
                size="small"
                className="marginTop"
                onClick={() => {
                  setModalIndexZero(0)
                  setIsActiveBtn(true);

                }}
              >
                ¡Vale!
              </Button>
              <br />
              <br />
            </div>
          </Modal>
          <Modal
           isOpen={ modalIndexZero ===1 || modalIndexZero ===2|| modalIndexZero ===4 }
            className="confirmation-register-modal confirmation-captain-modal"
          >
            <div className="confirmation-register-container">
              <br />
              <img src={Warning} alt="good hand" width="128" height="128"/>
              <Paragraph align="center" size="s">
                <b>¡Hey!</b>
                <br />
                <br />
                ¡No acertaste esta vez, pero tranquilo(a)!
                <br/>
                <br/>
                
                Recuerda que<b> para ganar comisiones de Bono Binario</b> sólo requieres a 1 personaa cada lado en la primera generación que compren un paquete ESP. 
               
                <br />
                <br />
              </Paragraph>

              <Button
                size="small"
                className="marginTop"
                onClick={() => {setModalIndexZero(0)
                  setIsActiveBtn(false);

                }}
                >
                ¡Vale!
              </Button>
              <br />
              <br />
            </div>
          </Modal>
          <Step order={5} visibility={step === 5 ? "visible" : "hidden"}>
            <Paragraph
              size="sx"
              align="center"
              style={{
                marginTop: "30px",
              }}
            >
              {dataQuestionOptions[1]?.question}
            </Paragraph>
            {dataQuestionOptions?.[1]?.options?.map((option, index) => (
              <Checkbox
                key={index}
                name="binary-bonus"
                label={option.option}
                checked={(option.status = selectedOne === index ? true : false)}
                className=""
                style={{
                  width: "288px",
                  padding: "0px 0px 0px 35px",
                  marginTop: "49px",
                }}
                onChange={() => {
                  setSelectedOne(selectedOne === index ? null : index);
                  handlerModalOne(index+1);
                }}
              />
            ))}
            <Button
              onClick={() => {setStep(step + 1);
                setIsActiveBtn(false);
              }}
          
              disabled={isActiveBtn? false : true}
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
            >
              Aceptar
            </Button>
          </Step>
          <Modal
            isOpen={modalIndexOne ===3}
            className="confirmation-register-modal confirmation-captain-modal"
          >
            <div className="confirmation-register-container">
              <br />
              <img src={CheckList} alt="good hand" width="128" height="128"/>
              <Paragraph align="center" size="s">
                <b>¡Excelente!</b>
                <br />
                <br />
                
                ¡Tienes clara la aplicación de ACDC!
                <br/>
                <br/>
                Efectivamente, en tu generación 12 entran 2048 personas a cada lado que compran <br/>un paquete de inicio ESP.
                      <br />
                <br />
              </Paragraph>
              <Button
                size="small"
                className="marginTop"
                onClick={() => {
                  setModalIndexOne(0)
                  setIsActiveBtn(true);

                }}
              >
                ¡Vale!
              </Button>
              <br />
              <br />
            </div>
          </Modal>
          <Modal
            isOpen={ modalIndexOne ===1 ||modalIndexOne === 2|| modalIndexOne ===4 }
            className="confirmation-register-modal confirmation-captain-modal"
          >
            <div className="confirmation-register-container">
              <br />
              <img src={Warning} alt="good hand" width="128" height="128"/>
              <Paragraph align="center" size="s">
                <b>¡Hey!</b>
                <br />
                <br />
                ¡No acertaste esta vez, pero tranquilo(a)! 
                <br/>
                <br/>
                
                La respuesta correccta es que aplicando <br/>
                ACDC en la generación 12 entran 2048<br/>
                personas a cada lado que compran un<br/>
                paquete de inicio ESP. 
                <br />
                <br />
              </Paragraph>

              <Button
                size="small"
                className="marginTop"
                onClick={() => {setModalIndexOne(0)
                  setIsActiveBtn(false)

                }}
                >
                ¡Vale!
              </Button>
              <br />
              <br />
            </div>
          </Modal>
        
          
          <Step order={6} visibility={step === 6 ? "visible" : "hidden"}>
            <Paragraph
              size="sx"
              align="center"
              style={{
                marginTop: "30px",
              }}
            >
              {dataQuestionOptions[2]?.question}
            </Paragraph>
            <GenerationTable
              headings={["Generación", "Comisión x ESP1", "Paquetes x ESP1"]}
              items={[
                ["G1", "$25 USD", "2"],
                ["G2", "$5 USD", "4"],
                ["G3", "$5 USD", "8"],
                ["G4", "$5 USD", "16"],
                ["G5", "$10 USD", "32"],
              ]}
            />
            {dataQuestionOptions?.[2]?.options?.map((option, index) => (
              <Checkbox
                key={index}
                name="binary-bonus"
                label={option.option}
                checked={(option.status = selectedTwo === index ? true : false)}
                className=""
                style={{
                  width: "288px",
                  padding: "0px 0px 0px 35px",
                  marginTop: "49px",
                }}
                onChange={() => {
                  setSelectedTwo(selectedTwo === index ? null : index);
                  handlerModalTwo(index+1);
                }}
              />
            ))}
            <Button
              onClick={() => {setStep(step + 1)
                setIsActiveBtn(false);
              }}
              disabled={isActiveBtn? false : true}
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
            >
              Aceptar
            </Button>
          </Step>
          <Modal
            isOpen={modalIndexTwo ===2}
            className="confirmation-register-modal confirmation-captain-modal"
          >
            <div className="confirmation-register-container">
              <br />
              <img src={CheckList} alt="good hand" width="128" height="128"/>
              <Paragraph align="center" size="s">
                <b>¡Excelente!</b>
                <br />
                <br />
                
                ¡Has calculado bien el Bono de Inicio<br/> 
                Rápido Bono Gen 5 para paquetes ESP 1!
                <br/>
                <br/>
                Efectivamente, podrías  ganar $ 510<br/>  
                dólares con este Bono si compras un<br/> 
                paquete ESP 1 en la Estrategia de ACDC.       <br />
                <br />
              </Paragraph>
              <Button
                size="small"
                className="marginTop"
                onClick={() => {
                  setModalIndexTwo(0)
                  setIsActiveBtn(true);

                }}
              >
                ¡Vale!
              </Button>
              <br />
              <br />
            </div>
          </Modal>
          <Modal
            isOpen={ modalIndexTwo ===1 ||modalIndexTwo === 3|| modalIndexTwo ===4 }
            className="confirmation-register-modal confirmation-captain-modal"
          >
            <div className="confirmation-register-container">
              <br />
              <img src={Warning} alt="good hand" width="128" height="128"/>
              <Paragraph align="center" size="s">
                <b>¡Hey!</b>
                <br />
                <br />
                ¡No calculaste bien, pero tranquilo(a)! 
                <br/>
                <br/>
                
                La respuesta es que podrías  ganar $ 510<br/>
                dólares con este Bono si compras un<br/> 
                paquete ESP 1 en la Estrategia de ACDC. 
                <br />
                <br />
              </Paragraph>

              <Button
                size="small"
                className="marginTop"
                onClick={() => {
                  setModalIndexTwo(0)
                  setIsActiveBtn(false);

                }}
                >
                ¡Vale!
              </Button>
              <br />
              <br />
            </div>
          </Modal>

          <Step order={7} visibility={step === 7 ? "visible" : "hidden"}>
            <Paragraph
              size="sx"
              align="center"
              style={{
                marginTop: "30px",
              }}
            >
              <span
                class="icon-info"
                style={{
                  display: "inline-flex",
                  width: "32px",
                  height: "32px",
                  backgroundColor: " #0063DD",
                  color: " white",
                  borderRadius: " 50%",
                  fontSize: "32px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></span>
              <br />
              <br />
              <b>Información Bonos Gen 5</b>
            </Paragraph>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "10px",
                maxWidth: "346px",
                width: "347px",
              }}
            >
              En el caso anterior asumimos la aplicación de la <br />
              estrategía de ACDC donde el crecimiento de <br />
              cada ganeración es el doble de la anterior. Sin <br /> embargo el
              proveedor seleccionado permite <br /> tener ilimitadas personas en
              cada generación,
              <br /> es decir, si decides tener más de 2 en tu primera <br />{" "}
              generación también{" "}
              <strong>
                te pagarán Bono de Inicio <br /> Rápido Gen 5 hasta la 5ta
                generación de cada <br /> persona
              </strong>{" "}
              que decidas tener en tu primera
              <br /> Generación. Sin embargo sólo podrás aplicar la <br />{" "}
              estrategía de ACDC con tus primeros 2.
              <br />
              <br />
              Adicionalmente es importante tener en cuenta
              <br />
              que si compras un paquete ESP 1 el pago de
              <br />
              comisiones del Bono Gen 5 por cada generación <br /> es el de la
              tabla anterior sin importar que tus
              <br />
              generaciones compren paquetes ESP 2 ó ESP 3.
            </Paragraph>
            <Button
              onClick={() => setStep(step + 1)}
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
            >
              Aceptar
            </Button>
          </Step>
          <Step order={8} visibility={step === 8 ? "visible" : "hidden"}>
            <Paragraph
              size="sx"
              align="center"
              style={{
                marginTop: "30px",
              }}
            >
              {dataQuestionOptions[3]?.question}
            </Paragraph>
            <GenerationTable
              headings={["Generación", "Comisión x ESP1", "Paquetes x ESP1"]}
              items={[
                ["G1", "$25 USD", "2"],
                ["G2", "$5 USD", "4"],
                ["G3", "$5 USD", "8"],
                ["G4", "$5 USD", "16"],
                ["G5", "$10 USD", "32"],
              ]}
            />
            {dataQuestionOptions?.[3]?.options?.map((option, index) => (
              <Checkbox
                key={index}
                name="binary-bonus"
                label={option.option}
                checked={
                  (option.status = selectedThree === index ? true : false)
                }
                className=""
                style={{
                  width: "288px",
                  padding: "0px 0px 0px 35px",
                  marginTop: "49px",
                }}
                onChange={() => {
                  setSelectedThree(selectedThree === index ? null : index);
                  setModalIndexThree(index+1)
                }}
              />
            ))}
            <Button
              onClick={() => {setStep(step + 1)
                setIsActiveBtn(false);
              }}
              disabled={selectedThree !== null ? false : true}
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
            >
              Aceptar
            </Button>
          </Step>
          <Modal
            isOpen={modalIndexThree ===4}
            className="confirmation-register-modal confirmation-captain-modal"
          >
            <div className="confirmation-register-container">
              <br />
              <img src={CheckList} alt="good hand" width="128" height="128"/>
              <Paragraph align="center" size="s">
                <b>¡Excelente!</b>
                <br />
                <br />
                
                ¡Has calculado bien el Bono de Inicio<br/>
                Rápido Bono Gen 5 para paquetes ESP 3!
                <br/>
                <br/>
                Efectivamente, podrías  ganar $ 2.140<br/>  
                dólares con este Bono si las  compras son<br/>
                paquetes ESP 3 en la Estrategia de ACDC.  <br />
                <br />
              </Paragraph>
              <Button
                size="small"
                className="marginTop"
                onClick={() => {
                  setModalIndexThree(0)
                  setIsActiveBtn(true);

                }}
              >
                ¡Vale!
              </Button>
              <br />
              <br />
            </div>
          </Modal>
          <Modal
            isOpen={ modalIndexThree ===1 ||modalIndexThree === 3|| modalIndexThree ===2 }
            className="confirmation-register-modal confirmation-captain-modal"
          >
            <div className="confirmation-register-container">
              <br />
              <img src={Warning} alt="good hand" width="128" height="128"/>
              <Paragraph align="center" size="s">
                <b>¡Hey!</b>
                <br />
                <br />
                ¡No calculaste bien, pero tranquilo(a)!
                <br/>
                <br/>
                
                La respuesta es que podrías  ganar $ 2.140<br/>  
                dólares con este Bono si las compras son<br/>
                paquetes ESP 3 en la Estrategia de ACDC.
                <br />
                <br />
              </Paragraph>

              <Button
                size="small"
                className="marginTop"
                onClick={() => {
                  setModalIndexThree(0)
                  setIsActiveBtn(false);

                }}
                >
                ¡Vale!
              </Button>
              <br />
              <br />
            </div>
          </Modal>
          <Step order={9} visibility={step === 9 ? "visible" : "hidden"}>
            <Paragraph
              size="sx"
              align="center"
              style={{
                marginTop: "30px",
              }}
            >
              <span
                class="icon-info"
                style={{
                  display: "inline-flex",
                  width: "32px",
                  height: "32px",
                  backgroundColor: " #0063DD",
                  color: " white",
                  borderRadius: " 50%",
                  fontSize: "32px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></span>
              <br />
              <br />
              <b>Información Bonos Gen 5</b>
            </Paragraph>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "24px",
                maxWidth: "100%",
              }}
            >
              El Proveedor seleccionado cuenta con un plan de <br />{" "}
              compensación de 12 Bonos de los cuales hoy sólo <br />
              nos hemos enfocado en 2, El bono de inicio
              <br /> rápido Gen5 y el bono binario, sin embargo en <br />
              el botón Descargar PDF que está abajo puedes
              <br /> conocer el detalle de todos los 12 Bonos.
            </Paragraph>
            <a
              download="Plan de Compensación_Gano Excel SA.pdf"
              href="/Plan de Compensación_Gano Excel SA.pdf"
              size="small"
              style={{
                backgroundColor: " #0063DD",
                color: "#FFF",
                textAlign: "center",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "22px",
                boxShadow: " 0px 0px 10px 0px rgba(0, 99, 221, 0.5019607843)",
                borderStyle: "none",
                borderRadius: "15px",
                padding: " 12px 0px",
                width: "159px",
                textDecoration: "none",
              }}
            >
              Descargar PDF
            </a>
            <Paragraph
              size="xs"
              align="center"
              style={{
                marginTop: "100px",
              }}
            >
              Descarga el PDF para poder continuar
            </Paragraph>
            <Button
              onClick={() => setStep(step + 1)}
              styles={{
                position: "relative",
                marginTop: "36px",
              }}
            >
              Continuar
            </Button>
          </Step>
          <Step order={10} visibility={step === 10 ? "visible" : "hidden"}>
            <Paragraph
              size="sx"
              align="center"
              style={{
                marginTop: "30px",
              }}
            >
              <span
                class="icon-info"
                style={{
                  display: "inline-flex",
                  width: "32px",
                  height: "32px",
                  backgroundColor: " #0063DD",
                  color: " white",
                  borderRadius: " 50%",
                  fontSize: "32px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></span>
              <br />
              <br />
              <b>Información Bonos Gen 5</b>
            </Paragraph>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "24px",
                maxWidth: "100%",
              }}
            >
              En la penúltima página del PDF encontrarás una <br />
              tabla final que se llama “Rangos de Calificación <br /> y
              Potencial” donde en la fila 9 dice: <br />
              <br />
              Total de Potencial Máximo de ganancias
              <br />
              Semanales por Compensaciones de Equipo
              <br /> Binario por Centro de Negocios y por país es de:
              <br /> $10.000 dólares. <br />
              <br />
              Con la estrategía de ACDC Asumiendo sólo Un
              <br /> Centro De Negocios y suponiendo que sólo se
              <br /> haga la estrategia en 10 Paises el potencial sería <br />
              de: <br />
              <br />
              <strong>$100.000 dólares a la semana</strong> <br />
              es decir
              <br /> <strong>$400.000 dólares mensuales</strong>
            </Paragraph>

            <Button
              onClick={() => setStep(step + 1)}
              styles={{
                position: "relative",
                marginTop: "36px",
              }}
            >
              Continuar
            </Button>
          </Step>

          <Step order={11} visibility={step === 11 ? "visible" : "hidden"}>
            <Paragraph
              size="sx"
              align="center"
              style={{
                marginTop: "30px",
              }}
            >
              {dataQuestionOptions[4]?.question}
            </Paragraph>
            {dataQuestionOptions?.[4]?.options?.map((option, index) => (
              <Checkbox
                key={index}
                name="binary-bonus"
                label={option.option}
                checked={
                  (option.status = selectedFort === index ? true : false)
                }
                className=""
                style={{
                  width: "288px",
                  padding: "0px 0px 0px 35px",
                  marginTop: "49px",
                }}
                onChange={() => {
                  setSelectedFort(selectedFort === index ? null : index);
                  setModalIndexFour(index+1);
                }}
              />
            ))}
            <Button
              onClick={() => {setStep(step + 1)
                setIsActiveBtn(true);
              }}
              disabled={isActiveBtn? false : true}
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
            >
              Aceptar
            </Button>
          </Step>
          <Modal
            isOpen={modalIndexFour ===3}
            className="confirmation-register-modal confirmation-captain-modal"
          >
            <div className="confirmation-register-container">
              <br />
              <img src={CheckList} alt="good hand" width="128" height="128"/>
              <Paragraph align="center" size="s">
                <b>¡Excelente!</b>
                <br />
                <br />
                
                ¡Has estado atento(a)!
                <br/>
                <br/>
                Efectivamente, si no realizas tu compra en <br/>
                el rol de Coordinador(a), tus ganancias<br/> serían de<b> 0 dólares.</b>
                <br />
              </Paragraph>
              <Button
                size="small"
                className="marginTop"
                onClick={() => {
                  setModalIndexFour(0)
                  setIsActiveBtn(true);

                }}
              >
                ¡Por qué!
              </Button>
              <br />
              <br />
            </div>
          </Modal>
          <Modal
            isOpen={ modalIndexFour ===1 ||modalIndexFour === 4|| modalIndexFour ===2 }
            className="confirmation-register-modal confirmation-captain-modal"
          >
            <div className="confirmation-register-container">
              <br />
              <img src={Warning} alt="good hand" width="128" height="128"/>
              <Paragraph align="center" size="s">
                <b>¡Hey!</b>
                <br />
                <br />
                ¡No acertaste esta vez, pero tranquilo(a)!
                <br/>
                <br/>
                Solo recuerda que si no realizas tu compra<br/> 
                el rol de Coordinador(a), tus ganancias<br/>
                serían de<b> 0 dólares.</b>
                <br />
                <br />
              </Paragraph>

              <Button
                size="small"
                className="marginTop"
                onClick={() => {
                  setModalIndexFour(0)
                  setIsActiveBtn(false);

                }}
                >
                ¡Por qué!
              </Button>
              <br />
              <br />
            </div>
          </Modal>
          <Step order={12} visibility={step === 12 ? "visible" : "hidden"}>
            <Paragraph
              size="sx"
              align="center"
              style={{
                marginTop: "24px",
              }}
            >
              Nombre Coordinador(a) si no realizas tu compra asegurada antes del
              EPA, quedarás en
              <br />
              <strong style={{ color: " #FF9090" }}>GAME OVER.</strong>
              <br />
              <br />
              <strong>¿Qué significa esto?</strong>
              <br />
              <br />
              Que <strong>NO</strong> podrás asistir al EPA, porque tu rol en el
              <br />
              EPA es Liderazgo y no podrás ser un líder frente
              <br /> a tu equipo, si no has hecho la compra con el
              <br /> proveedor seleccionado. Por lo tanto, no estarás
              <br /> dentro de la red comercial del proveedor
              <br />
              seleccionado y no te beneficiarás del
              <br /> crecimiento y las compras del equipo de tus
              <br /> Anfitriones porque estos pasarán a ser de tu
              <br />
              Director(a).
              <br />
              <br />
              Adicionalmente no podrás ser Capitán(a) y no serás parte del
              Ecosistema de Innovación ACDC en el que tendrás el apoyo de una
              red de profesionales, metodologías, tecnologías e inversionistas
              para materializar tu PDL.
            </Paragraph>

            <Button
              onClick={() => setStep(step + 1)}
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
            >
              Aceptar
            </Button>
          </Step>
          <Step order={13} visibility={step === 13 ? "visible" : "hidden"}>
            <br />

            <Paragraph size="sx" align="center">
              <b>¡Genial!</b>
              <br />
              Has completado el <b>Desafío B.</b>
            </Paragraph>
            <br />
            <img
              src={KeyHandCoordinator}
              alt="good-hand-coordinator"
              style={{ marginTop: "10px" }}
            />
            <CardCopyKey
              phrase="Desafío B Día 2 Coordinador"
              nameChallenge="Desafío B"
            />

            <Button
              style={{
                position: "relative",
                marginTop: "196px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Modal
            isOpen={step === 14}
            className="host-alignment-day-container epa-confirmation-modal"
            style={{
              marginTop: "241px",
              height: "300px"
            }}
          >
            <span className="title">
              <b>¡Conseguiste la Llave de hoy!</b>
            </span>
            <Paragraph align="center" size="sx">
              Esta te da el paso al <b>Día 3.</b>
              <br />
              <br /> Solo te falta completar el <b>Desafío C</b> que <br />
              ya se encuentra habilitado.
            </Paragraph>

            <Button size="small" onClick={() => saveResponse()}>
              Continuar
            </Button>
          </Modal>
        </>
      )}
      {challengeName === "Desafío C" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <ReadingSection>
              <Paragraph size="sx" align="left">
                Un verdadero líder sabe que sin importar cuanto haya logrado,
                todavía tiene mucho que aprender.” “Los líderes deben tener
                mentes abiertas e inquisitivas y deben tomarlo con calma para no
                hacer conclusiones basadas en suposiciones previas.” “Con lo que
                todo líder debe tener cuidado y debe evitar es dirigir por
                hábito, nunca aprender nada y solamente hacer lo que siempre ha
                hecho en la manera en que siempre lo ha hecho. Un líder así ya
                no es un líder, es un gerente.”
              </Paragraph>
            </ReadingSection>
            <Quote>
              <Paragraph size="s" align="left" style={{ margin: "0" }}>
                Tomado del libro:
              </Paragraph>
              <Paragraph
                size="s"
                align="left"
                style={{
                  margin: "0",
                  fontWeight: "700",
                }}
              >
                Lanzando una Revolución sobre el Liderazgo.
              </Paragraph>
            </Quote>
            <Button
              onClick={() => setStep(step + 1)}
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph size="s" align="center">
              <b>¡Vas súper!</b>
              <br />
              <br />
              Te queda poco para completar el Desafío C.
            </Paragraph>
            <img
              src={GoodHandCoordinator}
              alt="good-hand-coordinator"
              width="226"
              height="226"
              style={{
                marginTop: "10px",
              }}
            />
            <CardCopyKey
              phrase="Cierre Día 2 Coordinador"
              nameChallenge="Desafío C"
            />
            <Button
              onClick={() => {
                finishChallenge(null);
                navigate("/missions");
              }}
              styles={{
                position: "relative",
                marginTop: "26px",
              }}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
    </>
  );
};

export default CoordinatorSecondDay;
