import { Toggle } from "../../atoms/switch";

import NewHost from "../../../resources/images/new-host.png";
import MenuUserInfoOn from "../menu-user-info-on";
const GiftSwitchOn = ({ name, role, nameRole, range, onClick, items,img }) => {

    return (
        <div className="gift-switch">
            <MenuUserInfoOn name={name} role={role} nameRole={nameRole} range={range} />
            {!items && <Toggle onClick={onClick} />}
            {items?.map((item) => <div className={`content-buy ${item.wait && 'wait'}`}>
                <img src={NewHost} alt={item.title} style={{width: item.width || '30px'}} />
                {/* <span className={`${!item.wait && 'icon-state-selected'} icon`}>{item.wait && '...'}</span> */}
            </div>)}
        </div>
    );
};

export default GiftSwitchOn;