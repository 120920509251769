import axios from "axios";
import React, { createContext, useState } from "react";
import { API } from "../utils/constants";

const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userTemp, setUserTemp] = useState({});
    const [activeTempChallenge, setActiveTempChallenge] = useState({});
    const [medalModal, setMedalModal] = useState('');
    const [eventEpa, setEventEpa] = useState('');
    const [newTitleReference, setNewTitleReference] = useState('');
    const [changeRoleContext, setChangeRoleContext] = useState({});
    const [currentChallenge, setCurrentChallenge] = useState({});
    const [transationParam, setTransationParam] = useState(''); 
    const [scheduleParam, setscheduleParam] = useState([]); 
    const login = () => {
        axios.get(`${API.GET_INFORMATION.url}`).then((response) => {
            if (response?.status === 200) {
                setUser(response.data.data);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const logout = () => {
        setUser(null);
    };


    return (
        <UserContext.Provider value={{ user, login, logout, setUser, medalModal, setMedalModal, eventEpa, setEventEpa, changeRoleContext, setChangeRoleContext, currentChallenge, setCurrentChallenge,transationParam, setTransationParam,scheduleParam,setscheduleParam,activeTempChallenge, setActiveTempChallenge,userTemp, setUserTemp,newTitleReference, setNewTitleReference }}>
            {children}
        </UserContext.Provider>
    );
};

export { UserProvider, UserContext };
