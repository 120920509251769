import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import {
  getListOfHostWithTheirSelectApplicants,
} from "../../../middleware/services/epaCoordinatorServices";
import Paragraph from "../../atoms/paragraph";
import { ROLES } from "../../../middleware/utils/constants";
import Checkbox from "../../atoms/checkbox";
import Button from "../../atoms/button";

const ActivitySelectedTwo = ({ setNumPager, setStep, step, idCoordinator }) => {
  const [listHostsByCandidate, setListHostsByCandidate] = useState([]);
  const { eventEpa } = useContext(UserContext);
  useEffect(() => {
    fetchData();
    setNumPager(0);
  }, [eventEpa]);
  const fetchData = async () => {
    const list = await getListOfHostWithTheirSelectApplicants(idCoordinator);

    setListHostsByCandidate(list);
    return;
  };
  return (
    <>
      {listHostsByCandidate?.map((host) => (
        <>
          <br />
          <div className="candidate-text">
            <span className="role"> {ROLES[host.role]?.circle} </span>
            <span className="name">
              <b>{host.name}:</b>
            </span>
          </div>
          {host?.candidates?.map((candidate) => (
            <>
              <div className="candidadte-with-host">
                <span className="checbok-candidate">
                  <Checkbox
                    className="medal-switch__info__name"
                    name="hold"
                    label={<></>}
                    disabled={true}
                    checked={candidate?.
                      pdlConnectionDirector ? true : false}
                  />
                </span>
                <div className="label-candidate">
                  {candidate.name}
                  <br />
                  {ROLES[candidate.role || "candidate"].circle}{" "}
                  {ROLES[candidate.role || "candidate"].roleName}
                </div>
              </div>
            </>
          ))}
        </>
      ))}
      <Button
        onClick={() => {
          setStep(step + 1);
          setNumPager(0);
        }}
        className="margin-top"
      >
        Continuar
      </Button>
    </>
  );
};
export default ActivitySelectedTwo;
