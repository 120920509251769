import { useState } from "react";
import Card from "../../atoms/card";
import CopyField from "../../atoms/copy-field";
import Paragraph from "../../atoms/paragraph";

export const CardCopyKey = ({ phrase, nameChallenge }) => {
    const [copied, setCopied] = useState(false);

    return (
        <Card type="secondary" outline>
            <div className="card-copy-key" >
                <Paragraph align="center" size="s" className="first-paragraph">
                    Copia la clave que deberás pegar y enviar a TWOBOT para continuar:
                </Paragraph>
                <div className="card-copy-key__key">
                    {phrase}
                </div>
                <CopyField value={phrase} setStep={() => {
                    setCopied(true);
                }} />
                <Paragraph align="center" size="xs" className="second-paragraph">
                    Podrás copiar la clave en cualquier momento <br/> desde la <span className="icon-info"></span> información del <b>{nameChallenge}</b> en <br/>
                    <b>Mi Progreso</b>
                </Paragraph>
            </div>
        </Card>
    );
};