import { useParams } from "react-router-dom";
import Step from "../../molecules/step";
import Card from "../../atoms/card";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import CheckIcon from "../../../resources/images/check-icon.png";
import GoodHandCoordinator from "../../../resources/images/good-hand-coordinator.png";
import WarningIcon from "../../../resources/images/warning.png";

import Checkbox from "../../atoms/checkbox";

import { useContext, useEffect, useState } from "react";

import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import { UserContext } from "../../../middleware/providers/user-context";
import { Quote } from "../../molecules/quote";
import CoordinatorCheckList from "../../../resources/images/coordinator-check-list.png";
import ClapHandCoordinator from "../../../resources/images/clap-hand-coordinator.png";
import KeyHandCoordinator from "../../../resources/images/key-hand-coordinator-large.png";
import { CardCopyKey } from "../../molecules/card-copy-key";
import Modal from "../../atoms/modal";
import { toLowercaseAndCapitalize } from "../../../middleware/utils/toLowercaseAndCapitalize";

const CoordinatorFirstDay = ({ finishChallenge, step, setStep, challenge }) => {
  const { challengeName } = useParams();

  const { user } = useContext(UserContext);

  const [dataHost, setDataHost] = useState();
  const [dataQuestionOptions, setDataQuestionOptions] = useState([]);
  const [selectOption, setSelectOption] = useState();

  const [indexCheck, setIndexCheck] = useState([]);

  useEffect(() => {
    getListOfNewHosts(user?._id);
  }, []);
  const getCandidatAplicatProgressPoint = async (host, day, opt) => {
    const params = day ? { day } : undefined;

    try {
      const response = await axios.get(
        `${API.PROGRESS_POINT.url}/${host?._id}/?action=PROGRESS_POINT`,
        {
          params,
        }
      );

      if (response.status === 200) {
        setSelectOption(opt);
        const question = response.data?.data[1]?.questions[opt];
        const newEntry = {
          id: host?._id,
          name: host?.name,
          role: host?.role,
          questions: question,
        };

        setDataQuestionOptions((prev) => {
          const filteredPrev = prev.filter((item) => item.id !== host?._id);
          return [...filteredPrev, newEntry];
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getListOfNewHosts = async (idUser) => {
    await axios
      .get(`${API.LIST_OF_NEW_HOSTS.url}/${idUser}?action=LIST_OF_NEW_HOSTS`)
      .then((response) => {
        setDataHost(response.data.data);
      })
      .catch((err) => console.error(err));
  };
  const [open, setOpen] = useState(false);
  const openModalCheckboxSelect = (opt) => {
    dataHost.forEach((host) => {
      getCandidatAplicatProgressPoint(host, "day one", opt);
    });

    setOpen(true);
  };
  const [nextOption, setNextOption] = useState(0);
  const handlerCheck = (opt) => {
   
    setIndexCheck((prev) => {
      if (prev.includes(opt)) {
        return prev.filter((item) => item !== opt);
      }
      return [...prev, opt];
    });
  };

  return (
    <>
      {challengeName === "Desafío A" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <div className="day-rules-info">
              <span className="greet">¡Hey!</span>
              <img src={WarningIcon} alt="warning-icon" />
              <Card className="card-warning" type="secondary">
                <Paragraph
                  className="card-warning__text"
                  align="center"
                  size="s"
                >
                  <b>
                    Este desafío se realiza por fuera de la
                    <br /> Plataforma ACDC.{" "}
                  </b>
                  <br />
                  <br />
                  Cuando tu Director(a) reciba y confirme <br />
                  que le enviaste la nota de voz <br />
                  correspondiente al <b>Desafío A</b> podrás <br />
                  continuar con tus desafíos diarios.
                </Paragraph>
              </Card>
              <Button onClick={() => setStep(step + 1)}>¡Vale!</Button>
            </div>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <br />

            <Paragraph size="sx" align="center">
              <b>¡Genial!</b> <br />
              <br />
              Has completado el <b>Desafío A.</b>
            </Paragraph>

            <img
              src={ClapHandCoordinator}
              alt="ClapHandCoordinator"
              style={{ marginTop: "10px" }}
            />
            <Paragraph
              size="s"
              align="center"
              style={{
                backgroundColor: "#1A2233",
                marginTop: "28px",
                padding: "25px 27px 31px",
                maxWidth: "289px",
                borderRadius: "10px",
              }}
            >
              Ya puedes continuar con el <b>Desafío B</b> del <br />{" "}
              <b> Día 1.</b>
              <br />
              <br />
              <b>👋🏻 Ya nos vemos.</b>
            </Paragraph>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Button
              style={{
                marginTop: "20px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Vale
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío B" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <Paragraph align="left" size="s">
              Hambre
            </Paragraph>

            <div className="reading-section">
              <div className="reading-section__content">
                <Paragraph align="left" size="s">
                  “Es responsabilidad del líder mantenerse siempre con Hambre.
                  Todo liderazgo comienza con Hambre. En el momento en que el
                  líder no tiene hambre, el líder deja de funcionar como tal. En
                  el momento en que el líder deja de moverse, el líder no está
                  dirigiendo y se requiere ambición para mantener al líder en
                  movimiento.”
                </Paragraph>
              </div>
            </div>
            <Quote>
              <br />
              Tomado del libro:
              <br />
              <b>Lanzando una Revolución sobre el Liderazgo.</b>
            </Quote>
            <Button
              onClick={() => setStep(step + 1)}
              className="rules-day-container-button"
              style={{
                position: "relative",
                marginTop: "96px;",
              }}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <br />

            <img
              src={CoordinatorCheckList}
              alt="CoordinatorCheckList"
              style={{ marginTop: "10px" }}
            />
            <Paragraph size="sx" align="center">
              Ahora vas a poder revisar las respuestas
              <br />
              que puso cada Anfitrión en su <b>Desafío B</b> del <br />
              <b>Día 1</b> en las actividades de:
            </Paragraph>
            <br />

            <Card
              type="tertiary"
              style={{
                display: "flex",
                width: "284px",
                padding: "10px 27px 10px 12px",
                alignItems: "center",
                marginBottom: "0",
                height: "50px",
              }}
            >
              <Paragraph
                align="center"
                size="s"
                style={{
                  margin: "0",
                  display: "flex",
                  alignItems: "center",
                  gap: "17px",
                }}
              >
                <span
                  class="icon-state-selected"
                  style={{
                    display: " inline-flex",
                    alignItems: " center",
                    border: " 2px solid white",
                    borderRadius: " 4px",
                    width: " 22px ",
                    height: "22px ",
                    fontSize: " 12px",
                  }}
                ></span>{" "}
                Selección Multiple de Hambre
              </Paragraph>
            </Card>
            <Card
              type="tertiary"
              style={{
                display: "flex",
                width: "284px",
                padding: "10px 27px 10px 12px",
                alignItems: "center",
                marginBottom: "0",
                height: "50px",
              }}
            >
              <Paragraph
                align="center"
                size="s"
                style={{
                  margin: "0",
                  display: "flex",
                  alignItems: "center",
                  gap: "17px",
                }}
              >
                <span
                  class="icon-state-selected"
                  style={{
                    display: "inline-flex",
                    flex: "1",
                    alignItems: "center",
                    border: "2px solid white",
                    borderRadius: "4px",
                    width: "22px",
                    height: "22px",
                    fontSize: " 12px",
                    padding: "0 4.62px 0 0.62px",
                  }}
                ></span>{" "}
                Selección múltiple del avance de la Meta que esperan cumplir.
              </Paragraph>
            </Card>
            <Card
              type="tertiary"
              style={{
                display: "flex",
                width: "284px",
                padding: "10px 27px 10px 12px",
                alignItems: "center",
                marginBottom: "0",
                height: "50px",
              }}
            >
              <Paragraph
                align="center"
                size="s"
                style={{
                  display: "flex",
                  width: "284px",
                  padding: "10px 27px 10px 12px",
                  alignItems: "center",
                  marginTop: "16px",
                  marginBottom: "0",
                  height: "50px",
                }}
              >
                <span
                  class="icon-pencil"
                  style={{
                    display: "inline-flex",
                    flex: " 1",
                    alignItems: " center",
                    width: "29px",
                    height: " 29px",
                    fontSize: "24px",
                  }}
                ></span>{" "}
                Actividad escrita de cómo va a lograr un alto desempeño.
              </Paragraph>
            </Card>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Button
              style={{
                position: "relative",
                marginTop: "196px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <br />

            <Paragraph size="sx" align="center">
              A continuación vas a poder revisar las <br />
              respuestas que puso cada Anfitrión en su <br />
              <b>Desafío B</b> del <b>Día 1</b> en las actividades de:
            </Paragraph>
            <br />

            <Card
              type="tertiary"
              style={{
                display: "flex",
                width: "284px",
                padding: "10px 27px 10px 12px",
                alignItems: "center",
                marginBottom: "0",
                height: "50px",
              }}
            >
              <Paragraph
                align="center"
                size="sx"
                style={{
                  margin: "0",
                  display: "flex",
                  alignItems: "center",
                  gap: "17px",
                }}
              >
                <span
                  class="icon-state-selected"
                  style={{
                    display: " inline-flex",
                    alignItems: " center",
                    border: " 2px solid white",
                    borderRadius: " 4px",
                    width: " 22px ",
                    height: "22px ",
                    fontSize: " 12px",
                  }}
                ></span>{" "}
                Selección Multiple de Hambre
                {indexCheck.includes(0) ? (
                  <img
                    src={CheckIcon}
                    alt="check-icon"
                    width="18"
                    height="18"
                  />
                ) : (
                  ""
                )}
              </Paragraph>
            </Card>
            <Button
              size="small"
              style="ghost"
              styles={{
                position: "relative",
                marginTop: "66px",
                marginBottom: "-29px",
              }}
              onClick={() => openModalCheckboxSelect(0)}
            >
              Ver respuesta
            </Button>
            <Card
              type="tertiary"
              style={{
                display: "flex",
                width: "284px",
                padding: "10px 27px 10px 12px",
                alignItems: "center",
                marginBottom: "0",
                height: "50px",
              }}
            >
              <Paragraph
                align="center"
                size="s"
                style={{
                  margin: "0",
                  display: "flex",
                  alignItems: "center",
                  gap: "17px",
                }}
              >
                <span
                  class="icon-state-selected"
                  style={{
                    display: "inline-flex",
                    flex: "1",
                    alignItems: "center",
                    border: "2px solid white",
                    borderRadius: "4px",
                    width: "22px",
                    height: "22px",
                    fontSize: " 12px",
                    padding: "0 4.62px 0 0.62px",
                  }}
                ></span>{" "}
                Selección múltiple del avance de la Meta que esperan cumplir.
                {indexCheck.includes(1) ? (
                  <img
                    src={CheckIcon}
                    alt="check-icon"
                    width="18"
                    height="18"
                  />
                ) : (
                  ""
                )}
              </Paragraph>
            </Card>
            <Button
              size="small"
              style="ghost"
              styles={{
                position: "relative",
                marginTop: "66px",
                marginBottom: "-29px",
              }}
              onClick={() => openModalCheckboxSelect(1)}
            >
              Ver respuesta
            </Button>
            <Card
              type="tertiary"
              style={{
                display: "flex",
                width: "284px",
                padding: "10px 27px 10px 12px",
                alignItems: "center",
                marginBottom: "0",
                height: "50px",
              }}
            >
              <Paragraph
                align="center"
                size="s"
                style={{
                  display: "flex",
                  width: "284px",
                  padding: "10px 27px 10px 12px",
                  alignItems: "center",
                  marginTop: "16px",
                  marginBottom: "0",
                  height: "50px",
                }}
              >
                <span
                  class="icon-pencil"
                  style={{
                    display: "inline-flex",
                    flex: " 1",
                    alignItems: " center",
                    width: "29px",
                    height: " 29px",
                    fontSize: "24px",
                  }}
                ></span>{" "}
                Actividad escrita de cómo va a lograr un alto desempeño.
                {indexCheck.includes(3) ? (
                  <img
                    src={CheckIcon}
                    alt="check-icon"
                    width="18"
                    height="18"
                  />
                ) : (
                  ""
                )}
              </Paragraph>
            </Card>
            <Button
              size="small"
              style="ghost"
              styles={{
                position: "relative",
                marginTop: "66px",
                marginBottom: "-29px",
              }}
              onClick={() => openModalCheckboxSelect(3)}
            >
              Ver respuesta
            </Button>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Button
              style={{
                position: "relative",
                marginTop: "196px",
              }}
              onClick={() => setStep(step + 1)}
              disabled={indexCheck.length === 3 ? false : true}
            >
              Continuar
            </Button>
          </Step>
          <Modal
            isOpen={open}
            className="host-alignment-day-container epa-confirmation-modal"
            style={{
              height: "577px",
              padding: "0px",
            }}
          >
            <span className="title">
              <b>(Pregunta)</b>
            </span>
            {dataQuestionOptions && (
              <div>
                <Paragraph align="center" size="xs">
                  ⚪ {dataQuestionOptions?.[nextOption]?.name}:
                  <br />
                  <b>{dataQuestionOptions[nextOption]?.questions?.question}</b>
                </Paragraph>
                <br />
                {dataQuestionOptions[nextOption]?.questions?.options ? (
                  dataQuestionOptions[nextOption]?.questions?.options
                    ?.filter((option) => option.status === false)
                    .map(
                      (option, index) =>
                        index < 4 && (
                          <>
                            <Checkbox
                              label={toLowercaseAndCapitalize(
                                option.question || option.option || ""
                              )}
                              checked={option.status}
                              style={{
                                position: "relative",
                                marginTop: "16px",
                                marginBottom: "0",
                                marginLeft: "24px",
                                fontSize: "12px",
                              }}
                              //onChange={() => handleSelectFoundation('Fundación Ancianos')}
                            />
                          </>
                        )
                    )
                ) : (
                  <>
                  
                    <Card
                      type="tertiary"
                      style={{
                        display: "flex",
                        width: "284px",
                        padding: "10px 27px 10px 12px",
                        alignItems: "center",
                        marginBottom: "0",
                        height: "50px",
                      }}
                    >
                      <Paragraph
                        align="center"
                        size="sx"
                        style={{
                          margin: "0",
                          display: "flex",
                          alignItems: "center",
                          gap: "17px",
                        }}
                      >
                        <span
                          class="icon-state-selected"
                          style={{
                            display: "inline-flex",
                            flex: "1",
                            alignItems: "center",
                            border: "2px solid white",
                            borderRadius: "2px",
                            width: "22px",
                            height: "22px",
                            fontSize: " 12px",
                            padding: "0 4.62px 0 0.62px",
                          }}
                        ></span>{" "}
                        <span
                          style={{
                            paddingLeft: "10px",
                            textAlign: "left",
                          }}
                        >
                          Aún no ha respondido su desafío B.
                        </span>
                      </Paragraph>
                    </Card>
                    <br/>
                    <Card
                      type="tertiary"
                      style={{
                        display: "flex",
                        width: "284px",
                        padding: "10px 27px 10px 12px",
                        alignItems: "center",
                        marginBottom: "0",
                        height: "50px",
                      }}
                    >
                      <Paragraph
                        align="center"
                        size="sx"
                        style={{
                          margin: "0",
                          display: "flex",
                          alignItems: "center",
                          gap: "17px",
                        }}
                      >
                        <span
                          class="icon-state-selected"
                          style={{
                            display: "inline-flex",
                            flex: "1",
                            alignItems: "center",
                            border: "2px solid white",
                            borderRadius: "2px",
                            width: "18px",
                            height: "22px",
                            fontSize: " 12px",
                            padding: "0 4.62px 0 0.62px",
                          }}
                        ></span>{" "}
                        <span
                          style={{
                            paddingLeft: "10px",
                            textAlign: "left",
                            width: "236px",
                          }}
                        >
                          Puedes consultar la respuesta mas
                          <br /> tarde en la Pestaña “Mis Anfitriones”
                        </span>
                      </Paragraph>
                    </Card>

             
                  </>
                )}
                <br />
              </div>
            )}

            <Button
              size="small"
              onClick={() => {
              
                setNextOption(nextOption + 1);
                if (nextOption > 0) {
                  handlerCheck(selectOption);
                  setOpen(false);
                  setNextOption(0);
                }
              }}
              style={{ marginTop: "214px" }}
            >
              Continuar
            </Button>
            <br />
            <br />
          </Modal>
          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <br />

            <Paragraph size="sx" align="center">
              <b>¡Genial!</b>
              <br />
              Has completado el <b>Desafío B.</b>
            </Paragraph>
            <br />
            <img
              src={KeyHandCoordinator}
              alt="good-hand-coordinator"
              style={{ marginTop: "10px" }}
            />
            <CardCopyKey
              phrase="Desafío B Día 1 Coordinador"
              nameChallenge="Desafío B"
            />

            <Button
              style={{
                position: "relative",
                marginTop: "196px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Modal
            isOpen={step === 5}
            className="host-alignment-day-container epa-confirmation-modal"
            style={{
              marginTop: "241px",
              height: "40%",
            }}
          >
            <span className="title">
              <b>¡Conseguiste la Llave de hoy!</b>
            </span>
            <Paragraph align="center" size="sx">
              Esta te da el paso al <b>Día 2.</b>
              <br /> Solo te falta completar el <b>Desafío C</b> que <br />
              ya se encuentra habilitado.
            </Paragraph>

            <Button
              styles={{
                position: "relative",
                marginTop: "20px",
              }}
              size="small"
              onClick={() => finishChallenge(null)}
            >
              Continuar
            </Button>
          </Modal>
        </>
      )}
      {challengeName === "Desafío C" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <div className="reading-section">
              <div className="reading-section__content">
                <Paragraph align="left" size="sx">
                  “Recuerda, la ambición florece al estar <br />
                  a disgusto con el statu qu o. El descontento y el estar cómodo
                  no pueden coexistir. Si un líder está demasiado cómodo la
                  ambición se morirá y corre el riesgo de que la cómoda y suave
                  grava lo jale hacia la cuneta de la autocomplacencia.
                  <br />
                  <br />
                  La mayoría de las personas en esta vida están buscando el
                  camino fácil. Desean comodidad y para obtenerla, pagan el
                  precio de la mediocridad, así que se apresuran para llegar
                  hacia ese camino como vacas en un establo a la hora de la
                  comida.”
                </Paragraph>
              </div>
            </div>
            <Quote>
              <br />
              Tomado del libro:
              <br />
              <b>Lanzando una Revolución sobre el Liderazgo.</b>
            </Quote>
            <Button
              onClick={() => setStep(step + 1)}
              className="rules-day-container-button"
              style={{
                position: "relative",
                marginTop: "96px;",
              }}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <br />

            <Paragraph size="sx" align="center">
              <b>Vas súper!</b>
              <br />
              Te queda poco para completar el <b>Desafío C.</b>
            </Paragraph>
            <br />
            <img
              src={GoodHandCoordinator}
              alt="good-hand-coordinator"
              style={{ marginTop: "10px" }}
            />
            <CardCopyKey
              phrase="Cierre Día 1 Coordinador"
              nameChallenge="Desafío C"
            />

            <Button
              style={{
                position: "relative",
                marginTop: "196px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Continuar
            </Button>
          </Step>
        </>
      )}
    </>
  );
};

export default CoordinatorFirstDay;
