import Candidate from '../../../resources/images/candidate_black.png';

const MenuReferenceInfo = ({ name, role, nameRole, range, orientation = 'column', children, extraContent, icon, className = '', style, headingAlign = 'center', size}) => {

    return (
        <div className={`menu-user-info menu-user-info--${role} ${orientation} ${className}`} style={style}>
            <div className={`menu-user-info-avatar progress-${range}-8 ${size}`}>
               <img src={Candidate} alt='candidate' style={{
                marginTop: "-10px",
                marginLeft: "-10px",
                width: "109px",
                height: "109px"
               }}/>
            </div>
            <div className="menu-user-info-title">
                <h4 className={`heading ${headingAlign}`}>{name}</h4>
                <span >{nameRole}{icon}</span>
                {children}
            </div>
            {extraContent}
        </div>
    );
};

export default MenuReferenceInfo;